import React, { StrictMode, lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
LogRocket.init(import.meta.env.VITE_LOGROCKET_ID, {
    shouldRecordInteractions: false, // Disabilita registrazione click, input, ecc.
    dom: {
        inputSanitizer: true,
        textSanitizer: true,
        recordCanvas: false,
        disableVisibleElement: true
    },
    network: false
});
setupLogRocketReact(LogRocket);

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { ErrorBoundary } from 'react-error-boundary';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/scss/argon-dashboard-react.scss';
import 'react-loading-skeleton/dist/skeleton.css';

import IsAuth from './components/IsAuth';

import {
    MainStateProvider as StateProvider,
    ProductsAndMenusProvider,
    StatsDataProvider
} from './store';
import ErrorDisplay from './components/ErrorDisplay';
import Loading from './components/GUI/Loading';

const PrintQrCodes = lazy(() => import('./views/pages/PrintQrCodes'));
const AdminLayout = lazy(() => import('./layouts/Admin'));
const AuthLayout = lazy(() => import('./layouts/Auth'));
const AuthAction = lazy(() => import('./views/pages/AuthAction'));

ReactDOM.render(
    <StrictMode>
        <ErrorBoundary FallbackComponent={ErrorDisplay}>
            <Suspense fallback={<Loading />}>
                <BrowserRouter>
                    <Switch>
                        <Route path="/auth-action" render={(props) => <AuthAction />} />
                        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                        <StateProvider>
                            <ProductsAndMenusProvider>
                                <StatsDataProvider>
                                    <Route
                                        path="/admin/orders"
                                        render={() => <Redirect to="/orders" />}
                                    />
                                    <Route
                                        path="/qrCodesPrint"
                                        render={() => (
                                            <IsAuth>
                                                <PrintQrCodes />
                                            </IsAuth>
                                        )}
                                    />
                                    <Route
                                        path="/"
                                        render={(props) => (
                                            <IsAuth>
                                                <AdminLayout {...props} />
                                            </IsAuth>
                                        )}
                                    />
                                </StatsDataProvider>
                            </ProductsAndMenusProvider>
                        </StateProvider>
                    </Switch>
                </BrowserRouter>
            </Suspense>
        </ErrorBoundary>
    </StrictMode>,
    document.getElementById('root')
);
