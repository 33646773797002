import React from 'react';
import { Container } from 'reactstrap';

import defaultError from '@/assets/img/errors/default.svg';
import notFoundError from '@/assets/img/errors/404.svg';

const imagesByErrorStatus = {
    404: notFoundError,
    default: defaultError
};
const ErrorDisplay = ({ error }) => {
    const image = imagesByErrorStatus[error.status] || imagesByErrorStatus.default;

    console.log(error);

    return (
        <Container>
            <div className="d-flex align-items-center justify-content-center flex-column">
                <img src={image} alt="Error" style={{ width: '40%' }} />
                {error.status ? (
                    <h1>{error.message}</h1>
                ) : (
                    <>
                        <h1>Ops! Si è verificato un problema!</h1>
                        <p>Riprovare, se il problema persiste contatta l'assistenza.</p>
                    </>
                )}
            </div>
        </Container>
    );
};

export default ErrorDisplay;
